/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
// import OnboardingGuard from 'src/components/OnboardingGuard';
import GuestGuard from 'src/components/GuestGuard';
import { useSelector } from 'react-redux';

const routesConfig = (userType) => [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('src/views/pages/Error403View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/invite',
    component: lazy(() => import('src/views/auth/CompleteRegistrationView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/mfa-register',
    component: lazy(() => import('src/views/auth/RegisterMFAView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/mfa-verify',
    component: lazy(() => import('src/views/auth/VerifyMFAView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/home',
        component: lazy(() => import('src/views/reports/HomePage'))
      },
      {
        exact: true,
        path: '/app/reports/analytics',
        component: lazy(() => import('src/views/reports/AnalyticsDashboard'))
      },
      {
        exact: true,
        path: '/app',
        component: () => (userType === 'INTERNAL' ? <Redirect to="/app/reports/dashboard" /> : <Redirect to="/app/reports/home" />)
      },
      // onboarding routes are removed for the moment
      // as the BE functiionality is not yet implemented
      // {
      //   exact: true,
      //   guard: OnboardingGuard,
      //   path: '/app/onboarding/form',
      //   component: lazy(() => import('src/views/onboarding/Form'))
      // },
      // {
      //   exact: true,
      //   guard: OnboardingGuard,
      //   path: '/app/onboarding/invite',
      //   component: lazy(() => import('src/views/onboarding/Invite'))
      // },
      // {
      //   exact: true,
      //   guard: OnboardingGuard,
      //   path: '/app/onboarding/tutorial',
      //   component: lazy(() => import('src/views/onboarding/Tutorial'))
      // },
      // {
      //   exact: true,
      //   guard: OnboardingGuard,
      //   path: '/app/onboarding/checklist',
      //   component: lazy(() => import('src/views/onboarding/Checklist'))
      // },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/account/change-password',
        component: lazy(() => import('src/views/account/ChangePasswordView'))
      },
      {
        exact: true,
        path: '/app/reports/transactions',
        component: lazy(() => import('src/views/reports/transaction/TransactionListView'))
      },
      {
        exact: true,
        path: '/app/reports/transactions/:id/details/:type',
        component: lazy(() => import('src/views/reports/transaction/TransactionView'))
      },
      {
        exact: true,
        path: '/app/reports/ledger',
        component: lazy(() => import('src/views/reports/LedgerView'))
      },
      {
        exact: true,
        path: '/app/reports/order-items',
        component: lazy(() => import('src/views/reports/OrderItem/OrderItemListView'))
      },
      {
        exact: true,
        path: '/app/reports/order-items/:id/details',
        component: lazy(() => import('src/views/reports/OrderItem/OrderItemView'))
      },
      {
        exact: true,
        path: '/app/reports/order-items/:id/fulfilments/:fulfilmentId',
        component: lazy(() => import('src/views/reports/FulfilmentView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => (userType === 'INTERNAL' ? <Redirect to="/app/reports/dashboard" /> : <Redirect to="/app/reports/home" />)
      },
      {
        exact: true,
        path: '/app/reports/fulfilments',
        component: lazy(() => import('src/views/reports/FulfilmentListView'))
      },
      {
        exact: true,
        path: '/app/reports/fulfilments/:fulfilmentId/details/:id/:type',
        component: lazy(() => import('src/views/reports/FulfilmentView'))
      },
      {
        exact: true,
        path: '/app/reports/payouts',
        component: lazy(() => import('src/views/reports/payouts/PayoutsListView'))
      },
      // temporarily removed Balances page as it's currenlty not functional
      // {
      //   exact: true,
      //   path: '/app/reports/balances',
      //   component: lazy(() =>
      //   import('src/views/reports/payouts/Helpers/Balances/PayoutsListView')
      //   )
      // },
      // {
      //   exact: true,
      //   path: '/app/reports/balances/onboarding',
      //   component: lazy(() =>
      //     import('src/views/reports/payouts/Helpers/Balances/PayoutsOnboardingView')
      //   )
      // },
      {
        exact: true,
        path: '/app/reports/payouts/:transactionId/details',
        component: lazy(() => import('src/views/reports/payouts/PayoutsView'))
      },
      {
        exact: true,
        path: '/app/reports/payouts/methods',
        component: lazy(() => import('src/views/reports/payouts/PayoutMethodsListView'))
      },
      {
        exact: true,
        path: '/app/reports/payouts/methods/:channelId/details',
        component: lazy(() => import('src/views/reports/payouts/PayoutMethodEditView'))
      },
      {
        exact: true,
        path: '/app/reports/payouts/tiered',
        component: lazy(() => import('src/views/reports/payouts/TieredPayoutsListView'))
      },
      {
        exact: true,
        path: '/app/reports/gems',
        component: lazy(() => import('src/views/reports/gems'))
      },
      {
        exact: true,
        path: '/app/reports/vip/points',
        component: lazy(() => import('src/views/reports/vip/VipPointsListView'))
      },
      {
        exact: true,
        path: '/app/reports/vip/levels',
        component: lazy(() => import('src/views/reports/vip/VipLevelsListView'))
      },
      {
        exact: true,
        path: '/app/banners',
        component: lazy(() => import('src/views/management/banner/BannerListView'))
      },
      {
        exact: true,
        path: '/app/deals',
        component: lazy(() => import('src/views/management/deals/DealsListView'))
      },
      {
        exact: true,
        path: '/app/deals/:id/edit',
        component: lazy(() => import('src/views/management/deals/DealsEditView'))
      },
      {
        exact: true,
        path: '/app/deals/:id/copy/:countryCode',
        component: lazy(() => import('src/views/management/deals/DealsEditView'))
      },
      {
        exact: true,
        path: '/app/banners/add',
        component: lazy(() => import('src/views/management/banner/BannerEditView'))
      },
      {
        exact: true,
        path: '/app/banners/:id/edit',
        component: lazy(() => import('src/views/management/banner/BannerEditView'))
      },
      {
        exact: true,
        path: '/app/propositions',
        component: lazy(() => import('src/views/management/proposition/PropositionListView'))
      },
      {
        exact: true,
        path: '/app/propositions/:id/edit',
        component: lazy(() => import('src/views/management/proposition/PropositionEditView'))
      },
      {
        exact: true,
        path: '/app/countries',
        component: lazy(() => import('src/views/management/country/CountryListView'))
      },
      {
        exact: true,
        path: '/app/countries/add',
        component: lazy(() => import('src/views/management/country/CountryEditView'))
      },
      {
        exact: true,
        path: '/app/countries/:id/edit',
        component: lazy(() => import('src/views/management/country/CountryEditView'))
      },
      {
        exact: true,
        path: '/app/country-groups',
        component: lazy(() => import('src/views/management/CountryGroup/CountryGroupListView'))
      },
      {
        exact: true,
        path: '/app/country-groups/add',
        component: lazy(() => import('src/views/management/CountryGroup/CountryGroupEditView'))
      },
      {
        exact: true,
        path: '/app/country-groups/:id/edit',
        component: lazy(() => import('src/views/management/CountryGroup/CountryGroupEditView'))
      },
      {
        exact: true,
        path: '/app/currencies/fiat',
        component: lazy(() => import('src/views/management/currency/fiat/FiatCurrencyListView'))
      },
      {
        exact: true,
        path: '/app/currencies/fiat/add',
        component: lazy(() => import('src/views/management/currency/fiat/FiatCurrencyEditView'))
      },
      {
        exact: true,
        path: '/app/currencies/fiat/:id/edit',
        component: lazy(() => import('src/views/management/currency/fiat/FiatCurrencyEditView'))
      },
      {
        exact: true,
        path: '/app/providers',
        component: lazy(() => import('src/views/management/provider/ProviderListView'))
      },
      {
        exact: true,
        path: '/app/providers/add',
        component: lazy(() => import('src/views/management/provider/ProviderEditView'))
      },
      {
        exact: true,
        path: '/app/providers/:id/details',
        component: lazy(() => import('src/views/management/provider/ProviderDetailsView'))
      },
      {
        exact: true,
        path: '/app/providers/:id/users/invite',
        component: lazy(() => import('src/views/management/provider/AdminUserInviteView'))
      },
      {
        exact: true,
        path: '/app/providers/:id/users/:userId/edit',
        component: lazy(() => import('src/views/management/provider/AdminUserEditView'))
      },
      {
        exact: true,
        path: '/app/providers/:id/bundle-groups/add',
        component: lazy(() => import('src/views/management/provider/DigitalCurrencyEditView'))
      },
      {
        exact: true,
        path: '/app/providers/:id/bundle-groups/:digitalCurrencyId/edit',
        component: lazy(() => import('src/views/management/provider/DigitalCurrencyEditView'))
      },
      {
        exact: true,
        path: '/app/providers/:id/edit',
        component: lazy(() => import('src/views/management/provider/ProviderEditView'))
      },
      {
        exact: true,
        path: '/app/stock-management/providers',
        component: lazy(() => import('src/views/management/stock/providers/ProvidersListView'))
      },
      {
        exact: true,
        path: '/app/stock-management/providers/add',
        component: lazy(() => import('src/views/management/stock/providers/ProvidersEditView'))
      },
      {
        exact: true,
        path: '/app/stock-management/providers/:id/edit',
        component: lazy(() => import('src/views/management/stock/providers/ProvidersEditView'))
      },
      {
        exact: true,
        path: '/app/stock-management/providers/:id/details',
        component: lazy(() => import('src/views/management/stock/providers/ProvidersDetailsView'))
      },
      {
        exact: true,
        path: '/app/stock-management/stock-levels',
        component: lazy(() => import('src/views/management/stock/levels/StockProductListView'))
      },
      {
        exact: true,
        path: '/app/product-categories',
        component: lazy(() => import('src/views/management/productCategory/ProductCategoryListView'))
      },
      {
        exact: true,
        path: '/app/product-categories/add',
        component: lazy(() => import('src/views/management/productCategory/ProductCategoryEditView'))
      },
      {
        exact: true,
        path: '/app/product-categories/:id/edit',
        component: lazy(() => import('src/views/management/productCategory/ProductCategoryEditView'))
      },
      {
        exact: true,
        path: '/app/products',
        component: lazy(() => import('src/views/management/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/bundlegroups',
        component: lazy(() => import('src/views/management/provider/ProviderDetailsView/ProviderDigitalCurrencies'))
      },
      {
        exact: true,
        path: '/app/notifications',
        component: lazy(() => import('src/views/management/notifications/NotificationTypes'))
      },
      {
        exact: true,
        path: '/app/products/add',
        component: lazy(() => import('src/views/management/product/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/products/:id/edit',
        component: lazy(() => import('src/views/management/product/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/products/:id/details',
        component: lazy(() => import('src/views/management/product/ProductDetailsView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/add',
        component: lazy(() => import('src/views/management/product/productbundle/ProductBundleEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/:productBundleId/edit',
        component: lazy(() => import('src/views/management/product/productbundle/ProductBundleEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/:productBundleId/details',
        component: lazy(() => import('src/views/management/product/productbundle/ProductBundleDetailsView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/:productBundleId/prices/add',
        component: lazy(() => import('src/views/management/product/productbundle/priceoverride/PriceOverrideEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/:productBundleId/prices/:priceOverrideId/edit',
        component: lazy(() => import('src/views/management/product/productbundle/priceoverride/PriceOverrideEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/methods/add',
        component: lazy(() => import('src/views/management/product/productPaymentChannel/ProductPaymentChannelEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/methods/:productPaymentChannelId/edit',
        component: lazy(() => import('src/views/management/product/productPaymentChannel/ProductPaymentChannelEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/faq/add',
        component: lazy(() => import('src/views/management/product/productFAQ/ProductFAQEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/faq/:questionId/edit',
        component: lazy(() => import('src/views/management/product/productFAQ/ProductFAQEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/:productBundleId/banners/add',
        component: lazy(() => import('src/views/management/product/productbundle/banner/BannerEditView'))
      },
      {
        exact: true,
        path: '/app/products/:productId/bundles/:productBundleId/banners/:bannerId/edit',
        component: lazy(() => import('src/views/management/product/productbundle/banner/BannerEditView'))
      },
      {
        exact: true,
        path: '/app/promos',
        component: lazy(() => import('src/views/management/promotion/PromotionListView'))
      },
      {
        exact: true,
        path: '/app/promos/add',
        component: lazy(() => import('src/views/management/promotion/PromotionEditView'))
      },
      {
        exact: true,
        path: '/app/promos/:id/details',
        component: lazy(() => import('src/views/management/promotion/PromotionDetailsView'))
      },
      {
        exact: true,
        path: '/app/promos/:id/:option',
        component: lazy(() => import('src/views/management/promotion/PromotionEditView'))
      },
      {
        exact: true,
        path: '/app/exchange-rates',
        component: lazy(() => import('src/views/management/exchangeRate/ExchangeRateListView'))
      },
      {
        exact: true,
        path: '/app/exchange-rates/add',
        component: lazy(() => import('src/views/management/exchangeRate/ExchangeRateEditView'))
      },
      {
        exact: true,
        path: '/app/exchange-rates/:id/edit',
        component: lazy(() => import('src/views/management/exchangeRate/ExchangeRateEditView'))
      },
      {
        exact: true,
        path: '/app/taxes',
        component: lazy(() => import('src/views/management/tax/TaxListView'))
      },
      {
        exact: true,
        path: '/app/taxes/add',
        component: lazy(() => import('src/views/management/tax/TaxEditView'))
      },
      {
        exact: true,
        path: '/app/taxes/:id/edit',
        component: lazy(() => import('src/views/management/tax/TaxEditView'))
      },
      {
        exact: true,
        path: '/app/reports/payments',
        component: lazy(() => import('src/views/reports/Payments/PaymentsListView'))
      },
      {
        exact: true,
        path: '/app/reports/discount-points/transactions',
        component: lazy(() => import('src/views/reports/DiscountPoints/TransactionReport'))
      },
      {
        exact: true,
        path: '/app/reports/discount-points/transactions/:id/details',
        component: lazy(() => import('src/views/reports/DiscountPoints/TransactionReportDetailsView'))
      },
      {
        exact: true,
        path: '/app/reports/payments/:id/details',
        component: lazy(() => import('src/views/reports/Payments/PaymentDetailsView'))
      },
      {
        exact: true,
        path: '/app/payments/gateways',
        component: lazy(() => import('src/views/management/paymentOption/PaymentOptionListView'))
      },
      {
        exact: true,
        path: '/app/payments/gateways/:id/edit',
        component: lazy(() => import('src/views/management/paymentOption/PaymentOptionEditView'))
      },
      {
        exact: true,
        path: '/app/payments/gateways/:paymentOptionId/payers/:id/edit',
        component: lazy(() => import('src/views/management/paymentOption/PaymentOptionPayersEditView'))
      },
      {
        exact: true,
        path: '/app/payments/gateways/:paymentOptionId/payers/add',
        component: lazy(() => import('src/views/management/paymentOption/PaymentOptionPayersEditView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-cards',
        component: lazy(() => import('src/views/management/blockedCard/BlockedCardListView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-cards/:id/edit',
        component: lazy(() => import('src/views/management/blockedCard/BlockedCardEditView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-cards/add',
        component: lazy(() => import('src/views/management/blockedCard/BlockedCardEditView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-bins',
        component: lazy(() => import('src/views/management/paymentOptionBin/PaymentOptionBinListView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-bins/:id/edit',
        component: lazy(() => import('src/views/management/paymentOptionBin/PaymentOptionBinEditView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-bins/add',
        component: lazy(() => import('src/views/management/paymentOptionBin/PaymentOptionBinEditView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-payment-details',
        component: lazy(() => import('src/views/management/blockedPaymentDetail/BlockedPaymentDetailListView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-payment-details/:id/edit',
        component: lazy(() => import('src/views/management/blockedPaymentDetail/BlockedPaymentDetailEditView'))
      },
      {
        exact: true,
        path: '/app/payments/blocked-payment-details/add',
        component: lazy(() => import('src/views/management/blockedPaymentDetail/BlockedPaymentDetailEditView'))
      },
      {
        exact: true,
        path: '/app/payments/available-methods',
        component: lazy(() => import('src/views/management/availableChannel/AvailableChannelListView'))
      },
      {
        exact: true,
        path: '/app/payments/available-methods/:id/edit',
        component: lazy(() => import('src/views/management/availableChannel/AvailableChannelEditView'))
      },
      {
        exact: true,
        path: '/app/payments/methods',
        component: lazy(() => import('src/views/management/paymentChannel/PaymentChannelListView'))
      },
      {
        exact: true,
        path: '/app/payments/methods/:id/edit',
        component: lazy(() => import('src/views/management/paymentChannel/PaymentChannelEditView'))
      },
      {
        exact: true,
        path: '/app/payments/methods/add',
        component: lazy(() => import('src/views/management/paymentChannel/PaymentChannelEditView'))
      },
      {
        exact: true,
        path: '/app/payments/methods/groups',
        component: lazy(() => import('src/views/management/paymentChannelGroup/PaymentChannelGroupListView'))
      },
      {
        exact: true,
        path: '/app/payments/methods/groups/:id/edit',
        component: lazy(() => import('src/views/management/paymentChannelGroup/PaymentChannelGroupEditView'))
      },
      {
        exact: true,
        path: '/app/payments/methods/groups/add',
        component: lazy(() => import('src/views/management/paymentChannelGroup/PaymentChannelGroupEditView'))
      },
      {
        exact: true,
        path: '/app/fulfilments/fulfilment-methods',
        component: lazy(() => import('src/views/management/fulfilment/FulfilmentMethodListView'))
      },
      {
        exact: true,
        path: '/app/fulfilments/fulfilment-methods/:id/edit',
        component: lazy(() => import('src/views/management/fulfilment/FulfilmentMethodEditView'))
      },
      {
        exact: true,
        path: '/app/fulfilments/product-fulfilment-methods',
        component: lazy(() => import('src/views/management/fulfilment/ProductFulfilmentMethodListView'))
      },
      {
        exact: true,
        path: '/app/fulfilments/product-fulfilment-methods/add',
        component: lazy(() => import('src/views/management/fulfilment/ProductFulfilmentMethodEditView'))
      },
      {
        exact: true,
        path: '/app/fulfilments/product-fulfilment-methods/:id/edit',
        component: lazy(() => import('src/views/management/fulfilment/ProductFulfilmentMethodEditView'))
      },
      {
        exact: true,
        path: '/app/fulfilments/manual-fulfilment',
        component: lazy(() => import('src/views/management/fulfilment/ManualFulfilmentListView'))
      },
      {
        exact: true,
        path: '/app/stock-management/inventory',
        component: lazy(() => import('src/views/management/stock/inventory/StockInventoryListView'))
      },
      {
        exact: true,
        path: '/app/stock-management/inventory/add',
        component: lazy(() => import('src/views/management/stock/inventory/StockInventoryEditView'))
      },
      {
        exact: true,
        path: '/app/stock-management/inventory/:id/edit',
        component: lazy(() => import('src/views/management/stock/inventory/StockInventoryEditView'))
      },
      {
        exact: true,
        path: '/app/stock-management/inventory/:id/details',
        component: lazy(() => import('src/views/management/stock/inventory/StockInventoryDetailsView'))
      },
      {
        exact: true,
        path: '/app/payment-classification',
        component: lazy(() => import('src/views/management/classification'))
      },
      {
        exact: true,
        path: '/app/payment-classification/dictionary/add/:modelId?',
        component: lazy(() => import('src/views/management/classification/dictionary/ClassificationDictionaryEditView'))
      },
      {
        exact: true,
        path: '/app/payment-classification/dictionary/:id/:option',
        component: lazy(() => import('src/views/management/classification/dictionary/ClassificationDictionaryEditView'))
      },
      {
        exact: true,
        path: '/app/payment-classification/model/add',
        component: lazy(() => import('src/views/management/classification/model/ClassificationModelEditView'))
      },
      {
        exact: true,
        path: '/app/payment-classification/model/:id/:option',
        component: lazy(() => import('src/views/management/classification/model/ClassificationModelEditView'))
      },
      {
        exact: true,
        path: '/app/admin/users',
        component: lazy(() => import('src/views/management/adminUser/AdminUserListView'))
      },
      {
        exact: true,
        path: '/app/admin/users/invite',
        component: lazy(() => import('src/views/management/adminUser/AdminUserInviteView'))
      },
      {
        exact: true,
        path: '/app/admin/users/:id/edit',
        component: lazy(() => import('src/views/management/adminUser/AdminUserEditView'))
      },
      {
        exact: true,
        path: '/app/customers',
        component: lazy(() => import('src/views/management/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/customers/:id/details',
        component: lazy(() => import('src/views/management/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/customers/:id/edit',
        component: lazy(() => import('src/views/management/customer/CustomerDetailsEditView'))
      },
      {
        exact: true,
        path: '/app/gems/gem-fees',
        component: lazy(() => import('src/views/management/gemFees/GemFeesListView'))
      },
      {
        exact: true,
        path: '/app/gems/gem-fees/:id/edit',
        component: lazy(() => import('src/views/management/gemFees/GemFeesEditView'))
      },
      {
        exact: true,
        path: '/app/settings/schedulers',
        component: lazy(() => import('src/views/settings/SchedulerListView'))
      },
      {
        exact: true,
        path: '/app/settings/configuration',
        component: lazy(() => import('src/views/settings/SettingsListView'))
      },
      {
        exact: true,
        path: '/app/settings/configuration/:id/edit',
        component: lazy(() => import('src/views/settings/SettingsEditView'))
      },
      {
        exact: true,
        path: '/app/settings/clearcache',
        component: lazy(() => import('src/views/settings/ClearCacheSettingsView'))
      },
      {
        exact: true,
        path: '/app/settings/permissions',
        component: lazy(() => import('src/views/settings/SettingsPermissionsListView'))
      },
      {
        exact: true,
        path: '/app/settings/permissions/:id/edit',
        component: lazy(() => import('src/views/settings/SettingsPermissionEditView'))
      },
      {
        exact: true,
        path: '/app/settings/roles',
        component: lazy(() => import('src/views/settings/Roles/RolesListView'))
      },
      {
        exact: true,
        path: '/app/settings/roles/:roleId/edit',
        component: lazy(() => import('src/views/settings/Roles/RoleEditView'))
      },
      {
        exact: true,
        path: '/app/settings/roles/add',
        component: lazy(() => import('src/views/settings/Roles/RoleEditView'))
      },
      {
        exact: true,
        path: '/app/settings/app-versions',
        component: lazy(() => import('src/views/settings/AppVersions/AppVersionsListView'))
      },
      {
        exact: true,
        path: '/app/settings/app-versions/:id/edit',
        component: lazy(() => import('src/views/settings/AppVersions/AppVersionEditView'))
      },
      {
        exact: true,
        path: '/app/settings/app-versions/add',
        component: lazy(() => import('src/views/settings/AppVersions/AppVersionEditView'))
      },
      {
        exact: true,
        path: '/app/settings/console-settings',
        component: lazy(() => import('src/views/settings/ConsoleSettingsView'))
      },
      {
        exact: true,
        path: '/app/vip/faqs',
        component: lazy(() => import('src/views/management/VIP/faq/VipFaqListView'))
      },
      {
        exact: true,
        path: '/app/vip/faq/add',
        component: lazy(() => import('src/views/management/VIP/faq/VipFaqEditView'))
      },
      {
        exact: true,
        path: '/app/vip/faq/:questionId/edit',
        component: lazy(() => import('src/views/management/VIP/faq/VipFaqEditView'))
      },
      {
        exact: true,
        path: '/app/vip/promos',
        component: lazy(() => import('src/views/management/VIP/promotion/PromotionListView'))
      },
      {
        exact: true,
        path: '/app/vip/promos/add',
        component: lazy(() => import('src/views/management/VIP/promotion/PromotionEditView'))
      },
      {
        exact: true,
        path: '/app/vip/promos/:id/:action',
        component: lazy(() => import('src/views/management/VIP/promotion/PromotionEditView'))
      },
      {
        exact: true,
        path: '/app/content',
        component: lazy(() => import('src/views/management/content/ContentListView'))
      },
      {
        exact: true,
        path: '/app/content/:id/edit',
        component: lazy(() => import('src/views/management/content/ContentEditView'))
      },
      {
        exact: true,
        path: '/app/product-meta',
        component: lazy(() => import('src/views/management/productMeta/ProductMetaListView'))
      },
      {
        exact: true,
        path: '/app/product-meta/:id/edit',
        component: lazy(() => import('src/views/management/productMeta/ProductMetaEditView'))
      },
      {
        exact: true,
        path: '/app/dynamic-content/products',
        component: lazy(() => import('src/views/management/dynamicContent/ProductListView'))
      },
      {
        exact: true,
        path: '/app/dynamic-content/products/:id',
        component: lazy(() => import('src/views/management/dynamicContent/ProductDynamicContentView'))
      },
      {
        exact: true,
        path: '/app/dynamic-content/categories',
        component: lazy(() => import('src/views/management/dynamicContent/CategoriesListView'))
      },
      {
        exact: true,
        path: '/app/dynamic-content/categories/:id',
        component: lazy(() => import('src/views/management/dynamicContent/CategoryEditView'))
      },
      {
        exact: true,
        path: '/app/vip/points/',
        component: lazy(() => import('src/views/management/VIP/points/PointsListView'))
      },
      {
        exact: true,
        path: '/app/vip/points/:id/edit',
        component: lazy(() => import('src/views/management/VIP/points/PointsEditView'))
      },
      {
        exact: true,
        path: '/app/vip/levels/',
        component: lazy(() => import('src/views/management/VIP/levels/LevelListView'))
      },
      {
        exact: true,
        path: '/app/vip/levels/add',
        component: lazy(() => import('src/views/management/VIP/levels/LevelEditView'))
      },
      {
        exact: true,
        path: '/app/vip/levels/:id/edit',
        component: lazy(() => import('src/views/management/VIP/levels/LevelEditView'))
      },

      {
        exact: true,
        path: '/app/vip/challenges/',
        component: lazy(() => import('src/views/management/VIP/challenges/ChallengesListView'))
      },
      {
        exact: true,
        path: '/app/vip/challenges/add',
        component: lazy(() => import('src/views/management/VIP/challenges/ChallengesEditView'))
      },
      {
        exact: true,
        path: '/app/vip/challenges/:id/edit',
        component: lazy(() => import('src/views/management/VIP/challenges/ChallengesEditView'))
      },

      {
        exact: true,
        path: '/app/promo-codes',
        component: lazy(() => import('src/views/management/promoCode/PromoCodeListView'))
      },
      {
        exact: true,
        path: '/app/promo-codes/add',
        component: lazy(() => import('src/views/management/promoCode/PromoCodeEditView'))
      },
      {
        exact: true,
        path: '/app/promo-codes/:id/details',
        component: lazy(() => import('src/views/management/promoCode/PromoCodeDetailsView'))
      },
      {
        exact: true,
        path: '/app/promo-codes/:id/:option',
        component: lazy(() => import('src/views/management/promoCode/PromoCodeEditView'))
      },

      {
        exact: true,
        path: '/app/promo-code-groups',
        component: lazy(() => import('src/views/management/promoCodeGroup/PromoCodeGroupListView'))
      },
      {
        exact: true,
        path: '/app/promo-code-groups/add',
        component: lazy(() => import('src/views/management/promoCodeGroup/PromoCodeGroupEditView'))
      },
      {
        exact: true,
        path: '/app/promo-code-groups/:id/details',
        component: lazy(() => import('src/views/management/promoCodeGroup/PromoCodeGroupDetailsView'))
      },
      {
        exact: true,
        path: '/app/promo-code-groups/:id/:option',
        component: lazy(() => import('src/views/management/promoCodeGroup/PromoCodeGroupEditView'))
      },
      {
        exact: true,
        path: '/app/service-fees',
        component: lazy(() => import('src/views/management/serviceFees/ServiceFeesListView'))
      },
      {
        exact: true,
        path: '/app/service-fees/add',
        component: lazy(() => import('src/views/management/serviceFees/ServiceFeesEditView'))
      },
      {
        exact: true,
        path: '/app/service-fees/:id/details',
        component: lazy(() => import('src/views/management/serviceFees/ServiceFeesDetailsView'))
      },
      {
        exact: true,
        path: '/app/service-fees/:id/:option',
        component: lazy(() => import('src/views/management/serviceFees/ServiceFeesEditView'))
      },
      {
        exact: true,
        path: '/app/otp-methods/',
        component: lazy(() => import('src/views/management/otpMethods/OtpMethodsListView'))
      },
      {
        exact: true,
        path: '/app/otp-methods/:id/edit',
        component: lazy(() => import('src/views/management/otpMethods/OtpMethodsEditView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/products" />
      },
      {
        exact: true,
        path: '/app/discount-points/faqs',
        component: lazy(() => import('src/views/management/DiscountPoints/faq/DiscountPointsFaqListView'))
      },
      {
        exact: true,
        path: '/app/discount-points/faq/add',
        component: lazy(() => import('src/views/management/DiscountPoints/faq/DiscontPointsFaqEditView'))
      },
      {
        exact: true,
        path: '/app/discount-points/faq/:questionId/edit',
        component: lazy(() => import('src/views/management/DiscountPoints/faq/DiscontPointsFaqEditView'))
      },
      {
        exact: true,
        path: '/app/fraud-triggers',
        component: lazy(() => import('src/views/fraud/fraudTriggers/FraudTriggersListView'))
      },
      {
        exact: true,
        path: '/app/fraud-triggers/add',
        component: lazy(() => import('src/views/fraud/fraudTriggers/FraudTriggerEditView'))
      },
      {
        exact: true,
        path: '/app/fraud-triggers/:id/edit',
        component: lazy(() => import('src/views/fraud/fraudTriggers/FraudTriggerEditView'))
      },
      {
        exact: true,
        path: '/app/fraud-triggers/:id/view',
        component: lazy(() => import('src/views/fraud/fraudTriggers/FraudTriggerDetailsView'))
      },
      {
        exact: true,
        path: '/app/pii-data',
        component: lazy(() => import('src/views/management/piiData'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => {
              const hideNavigation = props.location.search.split(/[?&]/)
                .some(((item) => item === 'fullwidth=true'));
              const layoutProps = route.layout ? { hideNavigation } : {};

              return (
                <Guard>
                  <Layout {...layoutProps}>
                    {route.routes
                      ? renderRoutes(route.routes)
                      : <Component {...props} />}
                  </Layout>
                </Guard>
              );
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  const account = useSelector((state) => state.account);
  return renderRoutes(routesConfig(account?.user?.type));
}

export default Routes;
